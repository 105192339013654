import KTDatatable from '../scripts.bundle';
if (window.location.origin.includes("localhost")) {
    var sitelink = "https://localhost/guusbaan/public";
} else if (window.location.origin.includes("127.0.0.1")) {
    var sitelink = "http://127.0.0.1:8000";
} else {
    var sitelink = window.location.origin;
}

require("moment");

const lang = {
    processing: "Bezig...",
    "sLengthMenu": "_MENU_ resultaten weergeven",
    "sZeroRecords": "Geen resultaten gevonden",
    "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
    "sInfoEmpty": "Geen resultaten om weer te geven",
    "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
    "sInfoPostFix": "",
    "sSearch": "Zoeken:",
    "sEmptyTable": "Geen resultaten aanwezig in de tabel",
    "sInfoThousands": ".",
    "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
    "oPaginate": {
        "sFirst": "Eerste",
        "sLast": "Laatste",
        "sNext": "Volgende",
        "sPrevious": "Vorige"
    },
    "oAria": {
        "sSortAscending": ": activeer om kolom oplopend te sorteren",
        "sSortDescending": ": activeer om kolom aflopend te sorteren"
    }
};

function updateScrollable() {
    $(".dataTables_scroll").each(function () {
        let that = $(this);

        that.find(".dataTables_scrollBody").each(function () {
            if (this.clientWidth < this.scrollWidth) {
                that.addClass("scrollable");
            } else {
                that.removeClass("scrollable");
            }
        });
    });
}

(function( factory ) {
    "use strict";

    if ( typeof define === 'function' && define.amd ) {
        // AMD
        define( ['jquery'], function ( $ ) {
            return factory( $, window, document );
        } );
    }
    else if ( typeof exports === 'object' ) {
        // CommonJS
        module.exports = function (root, $) {
            if ( ! root ) {
                root = window;
            }

            if ( ! $ ) {
                $ = typeof window !== 'undefined' ?
                    require('jquery') :
                    require('jquery')( root );
            }

            return factory( $, root, root.document );
        };
    }
    else {
        // Browser
        factory( jQuery, window, document );
    }
}
(function( $, window, document ) {


    $.fn.dataTable.render.moment = function ( from, to, locale ) {
        // Argument shifting
        if ( arguments.length === 1 ) {
            locale = 'en';
            to = from;
            from = 'YYYY-MM-DD';
        }
        else if ( arguments.length === 2 ) {
            locale = 'en';
        }

        return function ( d, type, row ) {
            if (! d) {
                return type === 'sort' || type === 'type' ? 0 : d;
            }

            var m = window.moment( d, from, locale, true );

            // Order and type get a number value from Moment, everything else
            // sees the rendered value
            return m.format( type === 'sort' || type === 'type' ? 'x' : to );
        };
    };


}));

$(document).ready(function(){
    loadDatatable('bookingexperts_connections_table', {
        columns: [
            {title: "id"},
            {title: "Organisatie"},
            {title: "Status"},
            {title: "subscription_id"},
            {title: "organisation_id"},
            {title: "Acties"}
        ],
        columnDefs: [
            {
                sClass: "hidden",
                visible: false,
                targets: [0],
                searchable: true,
            },
        ],
    });

    // updateScrollable();
    //
    // $( window ).resize(function() {
    //     updateScrollable();
    // });
});

function loadDatatable(id, options = {}) {
    let $table = $(`#${id}`);

    let ajax_url = $table.data('ajax-url');

    if ($table.length === 0) {
        return;
    }

    if (ajax_url === undefined || ajax_url.length === 0) {
        console.error('Could not load DataTable data-ajax-url attribute missing');
        return;
    }

    let opts = {
        scrollX: true,
        pageLength: 25,
        responsive: true,
        ajax: ajax_url,
        language: lang,
        initComplete: function () {
            updateScrollable();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            $(`#${id}_filter > label > input[type=search]`).addClass('form-control form-control-sm');
            $(`#${id}_length > label > select`).addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $(`#${id}_filters tr`);
            $(`#${id}_filters`).remove();
            $(`#${id} > thead`).append(html);
            $(`#${id}_filters`).removeClass('d-none');

            $(`#${id}_filters_submit`).on('click', function(){
                var filters = $(`#${id}_filters_form`).serializeArray();

                var new_data_url = ajax_url+'?'+$.param(filters);


                $(`#${id}`).DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $(`#${id}_filters_submit`).click();
                }
            });
            $(`#${id}_filters_reset`).on('click', function(){
                $(`#${id}_filters_form`)[0].reset();
                $(`#${id}`).DataTable().ajax.url(ajax_url).load();
            });
        },
        ...options
    }

    $table.dataTable(opts);
}



function bookingExpertsConnectionsTable(){
    var gebruikers_table_url = sitelink+"/dt/connections/bookingexperts";

    $('#gebruikers_table').DataTable({
        "scrollX": true,
        "pageLength": 25,
        order: [[2, "asc"]],
        responsive: true,
        "ajax": gebruikers_table_url,
        columns: [
            {title: "Id"},
            {title: "Naam"},
            {title: "E-mail"},
            {title: "Status"},
            {title: "Role"},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": lang,
        "initComplete": function () {
            updateScrollable();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#gebruikers_table_filters tr');
            $('#gebruikers_table_filters').remove();
            $('#gebruikers_table > thead').append(html);
            $('#gebruikers_table_filters').removeClass('d-none');

            $('#gebruikers_table_filters_submit').on('click', function(){
                var filters = $('#gebruikers_table_filters_form').serializeArray();

                var new_data_url = gebruikers_table_url+'?'+$.param(filters);


                $('#gebruikers_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#gebruikers_table_filters_submit').click();
                }
            });
            $('#gebruikers_table_filters_reset').on('click', function(){
                $('#gebruikers_table_filters_form')[0].reset();
                $('#gebruikers_table').DataTable().ajax.url(gebruikers_table_url).load();
            });
        }
    });
}



function afgemelde_gebruikers_table(){
    var afgemelde_gebruikers_table_url = sitelink+"/dt/users/afgemeld";

    $('#afgemelde_gebruikers_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[1, "asc"]],
        responsive: true,
        "ajax": afgemelde_gebruikers_table_url,
        columns: [
            {title: "Id"},
            {title: "Naam"},
            {title: "Reden"},
            {title: "Afgemeld op", render: $.fn.dataTable.render.moment( 'X', 'DD MMMM YYYY', 'nl')},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            updateScrollable();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            $("#afgemelde_gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#afgemelde_gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#gebruikers_table_filters tr');
            $('#afgemelde_gebruikers_table_filters').remove();
            $('#afgemelde_gebruikers_table > thead').append(html);
            $('#afgemelde_gebruikers_table_filters').removeClass('d-none');

            $('#afgemelde_gebruikers_table_filters_submit').on('click', function(){
                var filters = $('#afgemelde_gebruikers_table_filters_form').serializeArray();

                var new_data_url = afgemelde_gebruikers_table_url+'?'+$.param(filters);


                $('#afgemelde_gebruikers_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#afgemelde_gebruikers_table_filters_submit').click();
                }
            });
            $('#afgemelde_gebruikers_table_filters_reset').on('click', function(){
                $('#afgemelde_gebruikers_table_filters_form')[0].reset();
                $('#afgemelde_gebruikers_table').DataTable().ajax.url(afgemelde_gebruikers_table_url).load();
            });


        }
    });
}
function aangemelde_gebruikers_table(){
    var afgemelde_gebruikers_table_url = sitelink+"/dt/users/aangemeld";

    $('#aangemelde_gebruikers_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[2, "asc"]],
        responsive: true,
        "ajax": afgemelde_gebruikers_table_url,
        columns: [
            {title: "Id"},
            {title: "Naam"},
            {title: "Aangemeld op", render: $.fn.dataTable.render.moment( 'X', 'DD MMMM YYYY', 'nl')},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {

            updateScrollable();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            $("#afgemelde_gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#afgemelde_gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#gebruikers_table_filters tr');
            $('#afgemelde_gebruikers_table_filters').remove();
            $('#afgemelde_gebruikers_table > thead').append(html);
            $('#afgemelde_gebruikers_table_filters').removeClass('d-none');

            $('#afgemelde_gebruikers_table_filters_submit').on('click', function(){
                var filters = $('#afgemelde_gebruikers_table_filters_form').serializeArray();

                var new_data_url = afgemelde_gebruikers_table_url+'?'+$.param(filters);


                $('#afgemelde_gebruikers_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#afgemelde_gebruikers_table_filters_submit').click();
                }
            });
            $('#afgemelde_gebruikers_table_filters_reset').on('click', function(){
                $('#afgemelde_gebruikers_table_filters_form')[0].reset();
                $('#afgemelde_gebruikers_table').DataTable().ajax.url(afgemelde_gebruikers_table_url).load();
            });


        }
    });
}


function profielen_table(){
    var profielen_table_url = sitelink+"/dt/profiles";

    $('#profielen_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[2, "asc"]],
        responsive: true,
        "ajax": profielen_table_url,
        columns: [
            {title: "Id"},
            {title: "Naam"},
            {title: "Traject"},
            {title: "Status"},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            updateScrollable();

            $("#profielen_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#profielen_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });

            var html = $('#profielen_table_filters tr');
            $('#profielen_table_filters').remove();
            $('#profielen_table > thead').append(html);
            $('#profielen_table_filters').removeClass('d-none');

            $('#profielen_table_filters_submit').on('click', function(){
                var filters = $('#profielen_table_filters_form').serializeArray();

                var new_data_url = profielen_table_url+'?'+$.param(filters);


                $('#profielen_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#profielen_table_filters_submit').click();
                }
            });
            $('#profielen_table_filters_reset').on('click', function(){
                $('#profielen_table_filters_form')[0].reset();
                $('#profielen_table').DataTable().ajax.url(profielen_table_url).load();
            });
        }
    });
}

function measurements_table(){
    var measurements_table_url = sitelink+"/dt/measurements/" + $('#measurements_table').data('userid');

    $('#measurements_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[1, "desc"]],
        responsive: true,
        "ajax": measurements_table_url,
        columns: [
            {title: "Id"},
            {title: "Datum", render: $.fn.dataTable.render.moment( 'X', 'DD-MM-YYYY' )},
            {title: "Gewicht"},
            {title: "Heupomvang"},
            {title: "Tailleomvang"},
            {title: "Locatie"},
            {title: "Opmerking"},
            {title: "Acties"},
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                targets: 0,
                searchable: true
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {

            updateScrollable();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            $("#measurements_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#measurements_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}
function events_management_table(){
    var events_management_table_url = sitelink+"/dt/events-management/";

    $('#events_management_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[2, "desc"]],
        responsive: true,
        "ajax": events_management_table_url,
        columns: [
            {title: "Id"},
            {title: "Titel"},
            {title: "Datum"},
            {title: "Aanmeldingen"},
            {title: "Acties"},
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {

            updateScrollable();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            $("#events_management_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            $("#events_management_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
        }
    });
}

function benchmarks_table(){
    var measurements_table_url = sitelink+"/dt/benchmarks/" + $('#benchmarks_table').data('userid');

    $.ajax({
        "url": measurements_table_url,
        "success": function(json) {
            var tableHeaders = "";

            const columns = [];

            $.each(json.columns, function(i, val){
                tableHeaders += "<th>" + val + "</th>";

                if(i === 1) {
                    columns.push({
                        title: val,
                        render: $.fn.dataTable.render.moment( 'X', 'DD-MM-YYYY' )
                    })
                } else {
                    columns.push({
                        title: val,
                    })
                }
            });

            $("#benchmarks_table").empty();
            $("#benchmarks_table").append('<table id="displayTable" class="display" cellspacing="0" width="100%"><thead><tr>' + tableHeaders + '</tr></thead></table>');

            $('#benchmarks_table').DataTable({
                "pageLength": 25,
                order: [[1, "desc"]],
                responsive: true,
                data: json.data,
                "columns": [

                    {title: "Id"},
                    {title: "Datum", render: $.fn.dataTable.render.moment( 'X', 'DD-MM-YYYY' )},
                    {title: "Ronden"},
                    {title: "Oefeningen"},
                    {title: "Herhalingen"},
                    {title: "Gewicht"},
                    {title: "Opmerking"},
                    {title: "Acties"},
                ],
                "columnDefs": [
                    {
                        sClass: "hidden",
                        visible: false,
                        "targets": [0],
                        searchable: true,
                    },
                ],
                "language": {
                    processing: "Bezig...",
                    "sLengthMenu": "_MENU_ resultaten weergeven",
                    "sZeroRecords": "Geen resultaten gevonden",
                    "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
                    "sInfoEmpty": "Geen resultaten om weer te geven",
                    "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
                    "sInfoPostFix": "",
                    "sSearch": "Zoeken:",
                    "sEmptyTable": "Geen resultaten aanwezig in de tabel",
                    "sInfoThousands": ".",
                    "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
                    "oPaginate": {
                        "sFirst": "Eerste",
                        "sLast": "Laatste",
                        "sNext": "Volgende",
                        "sPrevious": "Vorige"
                    },
                    "oAria": {
                        "sSortAscending": ": activeer om kolom oplopend te sorteren",
                        "sSortDescending": ": activeer om kolom aflopend te sorteren"
                    }
                },
                "initComplete": function () {
                    updateScrollable();

                    $('a').on('click', function(){
                        if($(this).attr('class') == null && $(this).attr('href').length > 2){
                            $('#kt_wrapper').removeClass('loaded');
                        }
                    });
                    $("#measurements_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
                    $("#measurements_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');
                }
            });
        },
        "dataType": "json"
    });

}
function bulk_measurements_table(){
    var bulk_measurements_table_url = sitelink+"/dt/measurements-bulk/";
    const bulk = new bulkMeasurements;
    const dt = $('#bulk_measurements_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[0, "asc"]],
        responsive: true,
        "ajax": bulk_measurements_table_url,
        columns: [
            {title: "id"},
            {title: "Naam"},
            {title: "Gewicht"},
            {title: "Heup omtrek"},
            {title: "Middel omtrek"},
            {title: "Meting locatie"},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: true,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            updateScrollable();
            bulk.init();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            // $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            // $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#bulk_measurements_table_filters tr');
            $('#bulk_measurements_table_filters').remove();
            $('#bulk_measurements_table > thead').append(html);
            $('#bulk_measurements_table_filters').removeClass('d-none');

            $('#bulk_measurements_table_filters_submit').on('click', function(){
                var filters = $('#bulk_measurements_table_filters_form').serializeArray();

                var new_data_url = bulk_measurements_table_url+'?'+$.param(filters);


                $('#bulk_measurements_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#bulk_measurements_table_filters_submit').click();
                }
            });
            $('#bulk_measurements_table_filters_reset').on('click', function(){
                $('#bulk_measurements_table_filters_form')[0].reset();
                $('#bulk_measurements_table').DataTable().ajax.url(bulk_measurements_table_url).load();
            });
        }
    });

    dt.on('search', function() {
        bulk.init();
    })

}

function practices_table(){
    var practices_table_url = sitelink+"/dt/practice/" + $('#practices_table').data('userid');;
    const bulk = new practice;
    const dt = $('#practices_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[0, "asc"]],
        responsive: true,
        "ajax": practices_table_url,
        columns: [
            {title: "id"},
            {title: "Naam"},
            {
                title: "5x",
                orderable: false,
                searchable: false
            },
            {
                title: "10x",
                orderable: false,
                searchable: false
            },
            {
                title: "15x",
                orderable: false,
                searchable: false
            },
            {
                title: "Acties",
                orderable: false,
                searchable: false
            }
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: true,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {
            updateScrollable();
            bulk.init();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            // $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            // $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#bulk_measurements_table_filters tr');
            $('#practices_table_filters').remove();
            $('#practices_table > thead').append(html);
            $('#practices_table_filters').removeClass('d-none');

            $('#practices_table_filters_submit').on('click', function(){
                var filters = $('#practices_table_filters_form').serializeArray();

                var new_data_url = practices_table_url+'?'+$.param(filters);


                $('#practices_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#practices_filters_submit').click();
                }
            });
            $('#bulk_measurements_table_filters_reset').on('click', function(){
                $('#bulk_measurements_table_filters_form')[0].reset();
                $('#bulk_measurements_table').DataTable().ajax.url(practices_table_url).load();
            });
        }
    });

    dt.on('search', function() {
        bulk.init();
    })

}

function bulk_benchmarks_table(){
    var bulk_benchmarks_table_url = sitelink+"/dt/benchmarks-bulk/";
    const bulk = new bulkBenchmarks;
    const dt = $('#bulk_benchmarks_table').DataTable({
        "scrollX": true,

        "pageLength": 25,
        order: [[0, "asc"]],
        responsive: true,
        "ajax": bulk_benchmarks_table_url,
        columns: [
            {title: "id"},
            {title: "Naam"},
            {title: "Traject(en)"},
            {title: "Ronden"},
            {title: "Oefeningen"},
            {title: "Herhalingen"},
            {title: "Gewicht"},
            {title: "Opmerking"},
            {title: "", searchable: false, orderable: false},
            {title: "Acties"}
        ],
        "columnDefs": [
            {
                sClass: "hidden",
                visible: true,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": {
            processing: "Bezig...",
            "sLengthMenu": "_MENU_ resultaten weergeven",
            "sZeroRecords": "Geen resultaten gevonden",
            "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
            "sInfoEmpty": "Geen resultaten om weer te geven",
            "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
            "sInfoPostFix": "",
            "sSearch": "Zoeken:",
            "sEmptyTable": "Geen resultaten aanwezig in de tabel",
            "sInfoThousands": ".",
            "sLoadingRecords": "<div class=\"loader\" style='margin: 0 auto;'></div>",
            "oPaginate": {
                "sFirst": "Eerste",
                "sLast": "Laatste",
                "sNext": "Volgende",
                "sPrevious": "Vorige"
            },
            "oAria": {
                "sSortAscending": ": activeer om kolom oplopend te sorteren",
                "sSortDescending": ": activeer om kolom aflopend te sorteren"
            }
        },
        "initComplete": function () {

            updateScrollable();

            bulk.init();

            $('a').on('click', function(){
                if($(this).attr('class') == null && $(this).attr('href').length > 2){
                    $('#kt_wrapper').removeClass('loaded');
                }
            });
            // $("#gebruikers_table_filter > label > input[type=search]").addClass('form-control form-control-sm');
            // $("#gebruikers_table_length > label > select").addClass('custom-select custom-select-sm form-control form-control-sm');


            var html = $('#bulk_benchmarks_table_filters tr');
            $('#bulk_benchmarks_table_filters').remove();
            $('#bulk_benchmarks_table > thead').append(html);
            $('#bulk_benchmarks_table_filters').removeClass('d-none');

            $('#bulk_benchmarks_table_filters_submit').on('click', function(){
                var filters = $('#bulk_benchmarks_table_filters_form').serializeArray();

                var new_data_url = bulk_benchmarks_table_url+'?'+$.param(filters);


                $('#bulk_benchmarks_table').DataTable().ajax.url(new_data_url).load();
            });
            $('.form-filter').keypress( function(event){
                if(event.keyCode == 13){
                    $('#bulk_benchmarks_table_filters_submit').click();
                }
            });
            $('#bulk_benchmarks_table_filters_reset').on('click', function(){
                $('#bulk_benchmarks_table_filters_form')[0].reset();
                $('#bulk_benchmarks_table').DataTable().ajax.url(bulk_benchmarks_table_url).load();
            });
        }
    });

    dt.on('search', function() {
        bulk.init();
    })

}
